import * as Sentry from "@sentry/browser";
import { logout } from "./client";

// toggle the first param of a _get, _post, _put, or _delete call
// to switch that route to the new haven backend!
// TODO: remove this once we're fully migrated to the new backend
export enum Backend {
  back = "back",
  haven = "haven",
}

export const backendUrl = (b: Backend): string => {
  // server-side fetches in dev need a different backend
  if (
    process.env.NEXT_PUBLIC_NODE_ENV === "development" &&
    typeof window === "undefined"
  ) {
    if (b === Backend.back) {
      return "http://back:1337";
    }

    if (b === Backend.haven) {
      return "http://haven:2337";
    }
  }

  if (b === Backend.haven) {
    return process.env.NEXT_PUBLIC_HAVEN_URL || "";
  }

  if (b === Backend.back) {
    return process.env.NEXT_PUBLIC_BACKEND_URL || "";
  }

  throw new Error("Invalid backend");
};

export const _get = async (b: Backend, url: string, errorContext?: string) => {
  const body = undefined;
  const canRetry = true;
  return _fetch(b, "GET", url, body, canRetry, errorContext);
};

export const _put = async (
  b: Backend,
  url: string,
  body: any,
  canRetry = false,
  errorContext?: string,
) => {
  return _fetch(b, "PUT", url, body, canRetry, errorContext);
};

export const _post = async (
  b: Backend,
  url: string,
  body?: any,
  canRetry = false,
  errorContext?: string,
) => {
  return _fetch(b, "POST", url, body, canRetry, errorContext);
};

export const _delete = async (
  b: Backend,
  url: string,
  errorContext?: string,
) => {
  const body = undefined;
  const canRetry = false;
  return _fetch(b, "DELETE", url, body, canRetry, errorContext);
};

const _fetch = async (
  backend: Backend,
  method: string,
  url: string,
  body: any | undefined,
  canRetry: boolean,
  errorContext?: string,
): Promise<any> => {
  const fullUrl = `${backendUrl(backend)}/api${url}`;

  return wrappedFetch(
    fullUrl,
    {
      method,
      body: JSON.stringify(body),
    },
    canRetry,
    errorContext,
  );
};

// wrappedFetch is a wrapper around fetch that adds:
// - auth and other headers to the options
// - a dev delay to simulate loading states
// - retries for network errors
// - response parsing and error handling for known error types
const wrappedFetch = async (
  input: RequestInfo | URL,
  options: RequestInit,
  canRetry: boolean,
  errorContext?: string,
) => {
  return parseAndHandleErrors(
    withRetry(
      () => withDevDelay(fetch(input, withDefaultFetchOptions(options))),
      canRetry,
      errorContext,
    ),
  );
};

// withDefaultFetchOptions enables cookie passing for auth, and other common headers
const withDefaultFetchOptions = (options: RequestInit): RequestInit => {
  return {
    ...options,
    credentials: "include",
    headers: {
      // CSRF prevention
      // https://www.owasp.org/index.php/Cross-Site_Request_Forgery_(CSRF)_Prevention_Cheat_Sheet#Use_of_Custom_Request_Headers
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/json",
    },
  };
};

// withDevDelay adds delay for local dev to show loading states
const withDevDelay = async <T>(promise: Promise<T>): Promise<T> => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === "development") {
    await new Promise((resolve) =>
      setTimeout(resolve, 400 + Math.random() * 1000),
    );
  }

  return promise;
};

// withRetry re-attempts Failed to fetch errors
// uses a backoff, before finally passing that error up as the result
export const tries = 3;
const backoff = 300;
export const withRetry = async <T>(
  promiseFn: () => Promise<T>,
  canRetry: boolean,
  errorContext?: string,
): Promise<T> => {
  if (!canRetry) {
    return promiseFn();
  }

  for (let attempt = 0; attempt < tries; attempt++) {
    try {
      // must `return await` here for the catch block to work
      return await promiseFn();
    } catch (error) {
      if (error instanceof TypeError && error.message === "Failed to fetch") {
        // log failed to fetch retries while logging out the error context
        console.error("Failed to fetch...", errorContext);

        if (attempt < tries - 1) {
          // delay with backoff
          await new Promise((resolve) =>
            setTimeout(resolve, backoff * 2 ** attempt),
          );
        } else {
          // override "failed to fetch" name for upstream messaging
          throw new Error("Network Error. Please try again later.");
        }
      } else {
        // re-throw other errors
        throw error;
      }
    }
  }

  // should be unreachable - need for compilation...
  throw new Error("Failed to fetch with retries");
};

// detects API errors and returns them in a more consistent format
// also detects network errors for possible upstream retries and better error logging
// also detects invalid credentials to (condtionally) take action on the jwt
const parseAndHandleErrors = async (promise: Promise<Response>) => {
  try {
    const resp = await promise;
    const result = await resp.json();

    // catch and convert strapi errors
    if (isStrapiError(result)) {
      throw new ApiError(
        result.error.message || "Unknown error",
        result.error.status,
      );
    }

    // catch and convert haven errors
    if (isHavenError(result)) {
      throw new ApiError(result.message || "Unknown error", result.statusCode);
    }

    // todo: handle other expected errors - perhaps gcp 5xx?

    return result;
  } catch (error) {
    if (error instanceof ApiError) {
      // check if jwt is expired or malformed
      if (isInvalidCredentialError(error)) {
        // if so, let's clear it...
        await logout();
      }

      // throw again for upstream handling of ApiError
      throw error;
    }

    // report non-api errors
    Sentry.captureException(error);

    throw error;
  }
};

// does it have error.messsage and error.status keys?
const isStrapiError = (result: any) => {
  return !!(result?.error?.message && result?.error?.status);
};

// haven error is shaped like:
// { message: string, statusCode: number }
const isHavenError = (result: any) => {
  if (typeof result === "object" && result !== null) {
    const e = result as {
      message?: string;
      statusCode?: number;
    };
    if (
      e.message !== undefined &&
      e.statusCode !== undefined &&
      e.statusCode >= 400
    ) {
      return true;
    }
  }

  return false;
};

// match expected error messages for invalid credentials:
// from missing, malformed, or expired jwt
export const isInvalidCredentialError = (error: ApiError) => {
  return (
    error.message === "Invalid credentials" ||
    error.message === "Missing or invalid credentials"
  );
};

// returns a message for expected errors, and captures
// unexpected errors with Sentry before returning a generic error message
export const getErrorMessage = (error: unknown) => {
  if (error instanceof ApiError || error instanceof Error) {
    return error.message;
  }

  return `Unexpected error: ${JSON.stringify(error)}`;
};

// wrapper for *expected* errors from strapi & haven
export class ApiError extends Error {
  public statusCode: number;
  constructor(msg: string, statusCode: number) {
    super();
    this.name = "ApiError";
    this.statusCode = statusCode;
    this.message = msg;
  }
}
