import queryStringify from "qs-stringify";

import type { Course } from "@/hl-common/strapi-types/Course";
import type { Module } from "@/hl-common/strapi-types/Module";
import type { Payload } from "@/hl-common/strapi-types/Payload";

import type {
  AuthChallengeRequest,
  AuthChallengeResponse,
} from "@/hl-common/types/api/AuthChallenge";
import type {
  EventEntity,
  IngestEventRequest,
} from "@/hl-common/types/api/Events";
import type { LandingPageEntity } from "@/hl-common/types/api/LandingPages";
import type { NameChangeRequestEntity } from "@/hl-common/types/api/NameChange";
import type {
  UpdateUserNotificationSettingsRequest,
  UserNotificationSettingsEntity,
} from "@/hl-common/types/api/NotificationSettings";
import type { RegisterRequest } from "@/hl-common/types/api/Register";
import type { SyncTagsRequest } from "@/hl-common/types/api/Tags";
import type {
  UpdateUserEmailRequest,
  UpdateUserNameRequest,
  UserEntity,
} from "@/hl-common/types/api/Users";
import type {
  VerifyOtpRequest,
  VerifyOtpResponse,
} from "@/hl-common/types/api/VerifyOtp";
import type {
  VerifyPasswordRequest,
  VerifyPasswordResponse,
} from "@/hl-common/types/api/VerifyPassword";

import type { CourseEntityWithUnmetPrerequisites } from "@/hl-common/types/api/Courses";
import type { CourseEntitiesByStatus } from "@/hl-common/types/api/CoursesByStatus";
import { Backend, _get, _post, _put } from "./fetch";

export async function me(): Promise<UserEntity> {
  return _get(Backend.haven, "/users/me");
}

export async function createAuthChallenge(
  body: AuthChallengeRequest,
): Promise<AuthChallengeResponse> {
  return _post(Backend.haven, "/auth-challenge/create", body);
}

// Note: These auth flow funcs should be called from within
// the UserContext to propertly handle jwt and user state.
export async function verifyOtp(
  body: VerifyOtpRequest,
): Promise<VerifyOtpResponse> {
  return _post(Backend.haven, "/auth-challenge/verify-otp", body);
}

// see above ^
export async function verifyPassword(
  body: VerifyPasswordRequest,
): Promise<VerifyPasswordResponse> {
  return _post(Backend.haven, "/auth-challenge/verify-password", body);
}

export async function logout(): Promise<{ success: boolean }> {
  return _post(Backend.haven, "/logout");
}

export async function registerUser(body: RegisterRequest): Promise<UserEntity> {
  return _put(Backend.haven, "/users/register", body);
}

export async function updateUserEmail(
  body: UpdateUserEmailRequest,
): Promise<UserEntity> {
  return _put(Backend.haven, "/users/email", body);
}

export async function updateUserName(
  body: UpdateUserNameRequest,
): Promise<UserEntity> {
  return _put(Backend.haven, "/users/name", body);
}

export async function getUserNotificationSettings(
  userId: number,
): Promise<UserNotificationSettingsEntity[]> {
  return _get(Backend.haven, `/users/${userId}/notification-settings`);
}

export async function getUserNameChangeRequests(
  userId: number,
): Promise<NameChangeRequestEntity[]> {
  return _get(Backend.haven, `/users/${userId}/name-change-requests`);
}

export async function updateUserNotificationSetting(
  userId: number,
  body: UpdateUserNotificationSettingsRequest,
): Promise<UserNotificationSettingsEntity> {
  return _put(Backend.haven, `/users/${userId}/notification-settings`, body);
}

export async function getCoursesWithStatus(): Promise<{
  data: CourseEntitiesByStatus;
}> {
  return _get(Backend.haven, "/courses-with-status");
}

export async function getCourseWithStatus(
  courseId: number,
): Promise<{ data: CourseEntityWithUnmetPrerequisites }> {
  return _get(Backend.haven, `/courses-with-status/${courseId}`);
}

export async function getCourse(courseId: number): Promise<Payload<Course>> {
  const query = {
    populate: { body: { populate: "*" } },
  };
  return _get(Backend.back, `/courses/${courseId}?${queryStringify(query)}`);
}

export async function getLandingPage(slug: string): Promise<LandingPageEntity> {
  return _get(Backend.haven, `/landing-pages/${slug}`);
}

export async function getModule(moduleId: number): Promise<Payload<Module>> {
  const query = {
    populate: {
      cards: {
        populate: {
          body: { populate: "*" },
          interaction: { populate: "*" },
          skipCondition: { populate: "*" },
        },
      },
    },
  };
  return _get(Backend.back, `/modules/${moduleId}?${queryStringify(query)}`);
}

export async function getEventsByCourse(
  courseID: number,
): Promise<{ data: EventEntity[] }> {
  return _get(Backend.haven, `/events/by-course/${courseID}`);
}

export async function getCompletedCardsByModule(
  moduleID: number,
): Promise<{ data: EventEntity[] }> {
  return _get(Backend.haven, `/events/completed-cards-by-module/${moduleID}`);
}

export async function createEvent(
  event: IngestEventRequest,
): Promise<{ success: boolean }> {
  const canRetry = true;
  const context = `POST /events: ${event.uuid}`;
  return _post(Backend.haven, "/events", event, canRetry, context);
}

export async function verifyCourseComplete(body: {
  eventId: number;
  verification: string;
}): Promise<{ data: EventEntity }> {
  return _get(
    Backend.haven,
    `/events/verify-course-complete/${body.eventId}/${body.verification}`,
  );
}

export async function getEventsCourseComplete(): Promise<{
  data: EventEntity[];
}> {
  return _get(Backend.haven, "/events/course-complete");
}

export async function syncTags(
  body: SyncTagsRequest,
): Promise<{ success: boolean }> {
  return _post(Backend.haven, "/tags", body);
}
